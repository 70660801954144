.blogSetWrapper{
    margin-top:18px;
  }
    
  .blogSetCollection{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    column-gap: 48px;
    row-gap: 48px;
    margin-bottom: 24px;
  }
  
  /* iPad Devices */
  @media only screen and (max-width: 1200px) {
    .blogSetCollection{
      column-gap:24px;
    }
  }
  
  @media only screen and (max-width: 800px) {
    .blogSetCollection{
      display: flex;
      flex-direction: column;
    }
    .blogSetTitle{
      margin-bottom: 4px;
    }
    .blogSetCollection{
      row-gap: 24px;
    }
  
  }
  
  @media only screen and (max-width: 400px) {
    .blogSetCollection{
      row-gap: 12px;
    }
  
  }