/* Monitor Devices */

.projectspage{
  width: var(--verylargeWidth);
  max-width: 80%;
  min-height: 100vh;
  height: auto;
  display: flex;
  flex-direction: column;
  padding:40px 0 80px 0;
}

/* Laptop Devices */
@media only screen and (max-width: 1500px) {
  .projectspage{
    width: var(--largeWidth);
  }
}

/* Large Tablet Devices */
@media only screen and (max-width: 1200px) {
  .projectspage{
    width: var(--mediumWidth);
  }
}

/* iPad Devices */
@media only screen and (max-width: 1000px) {
  .projectspage{
    width: var(--smallWidth);
  }
}

/* iPad Devices & lower */
@media only screen and (max-width: 1000px) {
  .projectspage{
    width: var(--smallWidth);
  }
}

@media only screen and (max-width: 700px) {
  .projectspage{
    max-width: 85%;
  }
}

@media only screen and (max-width: 500px) {
  .projectspage{
    max-width: 90%;
  }
}