.homepage{
    width: var(--verylargeWidth);
    max-width: 80%;
    min-height: 100vh;
    height: auto;
    display: flex;
    gap: 80px;
    flex-direction: column;
    padding:40px 0 40px 0;
  }
  
  /* Laptop Devices */
  @media only screen and (max-width: 1500px) {
    .homepage{
      width: var(--largeWidth);
    }
  }
  
  /* Large Tablet Devices */
  @media only screen and (max-width: 1200px) {
    .homepage{
      width: var(--mediumWidth);
    }
  }
  
  /* iPad Devices */
  @media only screen and (max-width: 1000px) {
    .homepage{
      width: var(--smallWidth);
    }
  }
  
  @media only screen and (max-width: 700px) {
    .homepage{
      max-width: 85%;
    }
  }
  
  @media only screen and (max-width: 500px) {
    .homepage{
      max-width: 90%;
    }
  }