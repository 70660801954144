.skillSetItemWrapper{
  position: relative;
  flex-basis:14.3%;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: transform 0.5s;
  cursor: pointer;
}

.skillSetItemWrapper:hover{
  transform: scale(1.05);
}

.skillSetItemWrapper img{
  max-width: 100%;
  border-radius: 20px 20px 0 0;
  border-bottom: 0.5px solid var(--colorTheme);
}

.skillDescription{
  margin:4px;
  text-align: center;
}

.starRating{
  margin-bottom:8px;
  text-align: center;
  font-size:12px;
}

@media only screen and (max-width: 800px) {
  .skillSetItemWrapper{
      flex-basis: 26%;
  }
}

@media only screen and (max-width: 300px) {
  .skillSetItemWrapper{
      flex-basis: 40%;
  }
}