.lineDiamondLineWrapper{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 8px;
}

.line{
    width: 100%;
    height:1px;
}

.design{
    margin:0 10px;
    display: flex;
    flex-direction: row;
}

.icon{
    margin:0 5px;
}