.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 6;
  background-color: rgba(0, 0, 0, 0.75);
}

.modal {
  position: fixed;
  top: 7.5vh;
  width: 800px;
  left: calc(50% - 400px);
  max-height: 85vh;
  padding:24px 24px 36px 24px;
  box-sizing: border-box;
  border-radius: 14px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 6;
  animation: slide-down 800ms ease-out forwards;
}

.modalWithScroll{
  /* max-height: 80vh; */
  padding: 4px 24px 24px 24px;
  overflow-y: scroll;
  animation: expand 800ms ease-out forwards;
}


.closeModal{
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 24px;
}

.closeModal span{
  cursor: pointer;
}

@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(-3rem);
    max-height: 0;
  }
  to {
    opacity: 1;
    transform: translateY(0);
    max-height: 100%;
  }
}

@keyframes expand {
  from {
    max-height: 0;
    overflow-y: hidden;
  }
  to {
    max-height: 80vh;
    overflow-y: scroll;
  }
}

.interactionEvents{
  margin-top:32px;
}

.interactionEventsContent{
  margin-top:8px;
}

.likeButton{
  display: inline-block;
}

.modal a:hover{
  text-decoration: underline;
}

@media (max-width: 1200px) {
  .modal {
    width: 600px;
    left: calc(50% - 300px);
  }
}

@media (max-width: 800px){
  .modal {
    width: 500px;
    left: calc(50% - 250px);
  }
}

@media (max-width: 700px){
  .modal {
    width: 400px;
    left: calc(50% - 200px);
  }
  .modal {
    padding: 16px 24px;
  }
}

@media (max-width: 500px){
  .modal {
    width: 300px;
    left: calc(50% - 150px);
    padding:16px 16px 16px 4px;
  }
}

@media (max-width: 350px){
  .modal {
    width: 250px;
    left: calc(50% - 125px);
  }
}

@media (max-width: 300px){
  .modal {
    width: 94%;
    left:4px;
    margin:4px;
  }
}