*{
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

:root{
  --verylargeWidth: 1200px;
  --largeWidth:1000px;
  --mediumWidth:800px;
  --smallWidth:700px;
}

::-moz-selection { /* Code for Firefox */
  color: white;
  background: #ffb703;
}

::selection {
  color: white;
  background: #ffb703;
}

body {
  margin: 0;
  background-color: var(--backgroundColorTheme);
  color:var(--colorTheme);
  text-align: var(--textAlign);
  font-family: var(--descriptionFont);
}

li{
  list-style-type: none;
  padding: 0;
  margin: 0;
}

ul{
  padding:0;
  margin:0;
}

a{
  text-decoration: none;
}

a:-webkit-any(){
  color:var(--colorTheme);
  text-decoration: none;
}

h1{
  margin:0;
  padding:0;
  font-size:42px;
  font-weight: normal;
}

h2{
  margin:0;
  padding:0;
  font-size:36px;
  font-weight: normal;
}

h3{
  margin:0;
  padding:0;
  font-size:24px;
  font-weight: normal;
}

h4{
  margin:0;
  padding: 0;
  font-size:21px;
  font-weight: normal;
}

h5{
  margin: 0;
  padding:0;
  font-size:18px;
  font-weight: normal;
}

h6{
  margin: 0;
  padding:0;
  font-size:16px;
  font-weight: normal;
}

button{
  border:0;
}

p{
  /* margin:32px 0; */
  font-size:19px;
  padding: 0;
  margin: 0;
}

.title{
  font-family: var(--titleFont);
}

strong{
  font-weight: 600;
}

.spin-wrapper .uil-spin-css > div > div{
  background: var(--mainTheme) !important;
}

.hideOverflow{
  overflow: hidden;
}

/* iPad Devices */
@media only screen and (max-width: 1000px) {
  .homepage{
    width: var(--smallWidth);
  }
  .articlepage{
    width: var(--smallWidth);
  }
  /* Font smaller */
  h1{
    font-size:36px;
  }
  h2{
    font-size:24px;
  }
  h3{
    font-size: 21px;
  }
  h4{
    font-size: 18px;
  }
  h5{
    font-size: 16px;
  }
  h6{
    font-size: 14px;
  }
  p{
    font-size: 18px;
  }
}


/* Medium Phones > 400 && < 600 */
@media only screen and (max-width: 600px) {
  /* Font smaller */
  h1{
    font-size:32px;
  }
  h2{
    font-size:21px;
  }
  h3{
    font-size: 18px;
  }
  h4{
    font-size: 16px;
  }
  h5{
    font-size: 14px;
  }
  h6{
    font-size: 14px;
  }
  p{
    font-size: 16px;
  }
}

/* Large Monitor > 1500 */
/* Large Devices > 1200 && < 1500 */
/* iPad Devices > 1000 && <1200*/
/* Very Large Phones > 800 && < 1000 */
/* Decent Large Phones > 700 && <800 */
/* Large Phones > 600 && < 800 */
/* Medium Phones > 500 && < 600 */
/* Semi Medium Phones > 400 && < 500 */
/* Small Phones >300 && <400 */