.skillBody{
  width:100%;
  word-spacing:3px;
}

.skillBody p,.skillBody h1,.skillBody h2,.skillBody h3,.skillBody h4,.skillBody h5,.skillBody h6{
  margin:4px 0;
}

.skillBody p {
  font-size: 1rem;
  line-height: 1.5;
}

.skillBody li{
  list-style-type:disc;
  font-size: 18px;
}

.skillBody img{
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  max-height: 300px;
}

.skillBody img + em{
  display: block;
  text-align: center;
  font-size:16px;
  font-style: normal;
  word-spacing:normal;
}
