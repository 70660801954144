.blogSetItemWrapper{
    max-width: 100%;
    display: flex;
    flex-direction: column;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 10px;
    flex-basis: 30%;
    cursor: pointer;
  }
  
  .image{
    position: relative;
    width:100%;
    border-radius: 10px 10px 0 0;
    z-index: 1;
  }
  
  .image img{
    width:100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px 10px 0 0;
    z-index: -1;
  }
  
  .ribbon{
    position: absolute;
    z-index: 2;
    width:90px;
    max-width: 40%;
    height:26px;
    left:0;
    top:20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
  }
  
  .ribbon::before{
    content:"";
    position: absolute;
    left:100%;
    top:0;
    width:0;
    height:0;
  }
  
  .ribbon::after{
    content:"";
    position: absolute;
    left:100%;
    top:50%;
    width:0;
    height:0;
    border-right: 13px solid transparent;
  }
  
  .content{
    padding:8px 24px 16px 20px;
  }
  
  .title{
    margin-bottom: 4px;
  }
  
  .date{
    margin-bottom: 4px;
  }
  
  .category{
    width:100%;
    margin-bottom: 16px;
  }
  
  .description{
    margin-bottom: 6px;
  }

  .blogSetItem{
    display: flex;
    flex-direction: column;
  }
  
@media only screen and (max-width: 900px) {
  .description{
    display: none;
  }
  .category{
    margin-bottom: 8px;
  }
  .blogSetItemWrapper{
    width: 100% !important;
  }


}

  @media only screen and (max-width: 800px) {
    .content{
      display: flex;
      flex-direction: column;
      padding: 10px 12px 10px 18px;
      width: 60%;
    }
  
    .blogSetItemWrapper{
      width: 100%;
      display: flex;
      flex-direction: row;
    }

    .blogSetItem{
      display: flex;
      flex-direction: row;
    }
  
    .image{
      width:40%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    .image img{
      border-radius: 10px 0px 0px 10px;
    }
  
    .description{
      margin-bottom: 6px;
      display: block;
    }

    .category{
      margin-bottom: 6px;
      display: block;
    }

    .title, .date{
      margin-bottom: 2px;
      display: block;
    }
  }
  
  @media only screen and (max-width: 700px) {
    .description{
      display: none;
    }
  }
  
  @media only screen and (max-width: 500px) {
    /* .duration{
      display: none;
    }
    .durationGold{
      display: none;
    } */
    .date{
      display: none;
    }
  }
  
  @media only screen and (max-width: 400px) {
    .title h3{
      font-size: 16px;
    }
    .category{
      display: none;
    }
    .duration{
      display: none;
    }
    .durationGold{
      display: none;
    }
 }

  @media only screen and (max-width: 300px) {
    .blogSetItemWrapper{
      flex-direction: column;
    }
  
    .image{
      width: 100%;
    }

    .image img{
      border-radius: 10px;
    }
  
    .content{
      width:100%;
    }
    .category{
      display: block;
      margin-top: 6px;
    }
    .duration{
      display: block;
    }
    .durationGold{
      display: block;
    }
 }