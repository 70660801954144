.skillHeader{
  padding:0 0 18px 0;
}   

.navigation{
  margin-bottom: 4px;
}

.name{
  margin-bottom:8px;
  font-family: var(--titleFont);
  text-align: center;
}

.headerContent{
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
}

.authorWrapper{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.authors span{
  white-space: nowrap;
}

.imageWithCaption{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.videoWrapper{
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}

.iframeVideo{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}


.image{
/* padding:0 5%; */
  border-radius: 2%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 4px;
}

.image img{
  width: 75%;
  object-fit: contain;
  border-radius: 2%;
}

.imageCaption p{
  font-size: 14px;
}

@media (max-width: 800px){
  .image img {
      width: 100%;
  }
  .name{
      margin-bottom:0px;
  }
}